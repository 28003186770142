export default class ResponsiveSize {
  static breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 1600,
  };

  static sizesByBreakpoint = {
    s: [[0.8, 'rem'], [0.9, 'px'], [1, 'px']],
    m: [[0.61, 'rem'], [0.8, 'px'], [1, 'px']],
    l: [[0.58, 'rem'], [0.8, 'px'], [1, 'px']],
    xl: [[0.4, 'rem'], [0.7, 'px'], [1, 'px']],
  };

  static getCSSValue(value: number | string): string {
    const size = parseInt(`${value}`, 10);

    if (size >= 50) {
      return ResponsiveSize.get(ResponsiveSize.sizesByBreakpoint.xl, size);
    }

    if (size >= 40) {
      return ResponsiveSize.get(ResponsiveSize.sizesByBreakpoint.l, size);
    }

    if (size >= 30) {
      return ResponsiveSize.get(ResponsiveSize.sizesByBreakpoint.m, size);
    }

    return ResponsiveSize.get(ResponsiveSize.sizesByBreakpoint.s, size);
  }

  static getCurrentBreakpoint(): string[] {
    const bps: string[] = [];

    Object.entries(ResponsiveSize.breakpoints).forEach(([key, size]) => {
      if (window.innerWidth >= size) {
        bps.push(key);
      }
    });

    return bps;
  }

  private static get(sizes: (string|number)[][], value: number): string {
    const sizeInx = Object.keys(this.breakpoints).indexOf(String(ResponsiveSize.getCurrentBreakpoint()));

    const size = sizes[sizeInx];

    const percentage = size[0] as number;
    const unit = size[1] as string;

    switch (unit) {
      case 'rem':
        return `${(value * percentage) / 16}rem`;
      default:
        return `${value * percentage}px`;
    }
  }

  static isMobileBreakpoint(): boolean {
    return !ResponsiveSize.getCurrentBreakpoint().includes('lg');
  }

  static onBreakpointChanged(callback: (...params: any) => void, immediate = false): void {
    const eventListenerCallback = () => {
      const breakpointChangedEvent = new CustomEvent('viewport.breakpoint.changed', {
        bubbles: true,
        detail: {
          windowWidth: window.innerWidth,
          breakpoints: Object.keys(ResponsiveSize.breakpoints),
          currentBreakpoints: ResponsiveSize.getCurrentBreakpoint(),
          isMobile: ResponsiveSize.isMobileBreakpoint(),
        },
      });
      callback(breakpointChangedEvent);
    };

    window.addEventListener('resize', eventListenerCallback);

    if (immediate) {
      eventListenerCallback();
    }
  }

  static removeOnBreakpointChanged(callback: (...params: any) => void): void {
    window.removeEventListener('viewport.breakpoint.changed', callback);
  }
}
