<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.142"
      height="10.607"
      viewBox="0 0 14.142 10.607"
  >
    <g transform="translate(456.084 -755.19) rotate(45)">
      <path
          d="M0,0H5"
          transform="translate(216.5 858.5)"
          fill="none" stroke="#E42313"
          stroke-linecap="round"
          stroke-width="3"
      />
      <line
          y2="9"
          transform="translate(221.5 849.5)"
          fill="none"
          stroke="#E42313"
          stroke-linecap="round"
          stroke-width="3"
      />
    </g>
  </svg>
</template>
